$gc = '.grid-stack-container'

{$gc}
  display flex

  &__column
    display flex
    flex-direction column
    flex 1 1 0
    flex-grow 1
    min-width 0
    min-height 0
    &:last-child
      margin-right 0

    &--reverse
      justify-content flex-end

  &__hide_wrapper
    width 100%

  &__item
    ^[0]__hide_wrapper--on &
      display none

