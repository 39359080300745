$root-index = 1

.slide-selector
  overflow hidden

  &__wrapper
    width 100%
  &__row
    display flex
    flex-wrap nowrap
    transform translate3d(0, 0, 0)

  &__item
    display inline-flex
    flex 0 0 auto
    user-select none

.button-slider
  width 100%
  margin-bottom 30px

  &__wrapper
    width 100%

  &__row
    list-style none
    padding 0
    margin 0
    &:first-child
      margin-top 0
    // ^[{$root-index}]__wrapper--hide ^[{$root-index}..-{$root-index}]

  &__item
    border 1px solid black
    margin 5px
    align-items center
    box-shadow 1px 1px gray
    padding: 7px 20px 8px;
    border-radius: 17px;
    background-color: #f8f8f8;

    &:first-child
      margin-left 1px

    &[data-selected="true"]
      background-color deepskyblue

  &__item__icon
      margin-right 10px

  @media screen and (min-width 1025px)
    &__row
      justify-content center








