.collapse
  position relative
  &-anchor
    margin 0
    padding 10px 20px
    cursor pointer
    display flex
    justify-content space-between
    align-items center
    span
      display flex
    &:after
      font-family 'Linearicons-Free'
      content "\e874"
      font-size 12px
      color #999
      margin-top -4px
  &-content
    position relative
    height 0
    overflow hidden
  &.opened
    .collapse-anchor:after
      transform rotate(180deg)
    .collapse-content
      height auto

