.dropdown
  position relative
  &-anchor
    margin 0
    padding 10px 20px
    > *
      cursor pointer
  &-list
    display none
    position absolute
    top 100%
    right 0
    padding 0
    margin 0
    list-style-type none
    border-radius 2px
    border solid 1px #e5e5e5
    background #fff
    box-shadow 0 1px 2px 0 rgba(0, 0, 0, 0.3)
    z-index 10
    &[data-show=true]
      display block
      .backdrop
        position fixed
        top 0
        right 0
        left 0
        bottom 0
        z-index -1
    &-item
      display flex
      padding 10px 20px
      flex-direction row
      align-items center
      cursor pointer
      word-break keep-all
      white-space nowrap
      &:hover
        color #fff
        background #3c89f9
        a
          color #fff !important
